<template>
  <div
    @mouseleave="hoveredRemove"
    class="main-navigation"
    ref="mainNav"
  >
    <ul
      @mouseover="hoveredTrigger"
      @mouseleave="hovered = false"
      :class="{ 'hover' : hovered, 'clicked' : clicked }"
    >
      <li
        :key="category.slug"
        v-for="category in visibleCategories"
        :class="{ 'offer' : [ 1163, 25831 ].includes(category.id) }"
      >
        <main-nav-inner
          :category="category"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import i18n from '@vue-storefront/i18n'
import SidebarMenu from '@vue-storefront/core/compatibility/components/blocks/SidebarMenu/SidebarMenu'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import { isServer } from '@vue-storefront/core/helpers/index'
import MainNavInner from 'theme/components/core/blocks/Custom/MainNavInner'

export default {
  data () {
    return {
      hovered: false,
      clicked: false
    }
  },
  components: {
    MainNavInner
  },
  mixins: [SidebarMenu],
  computed: {
    visibleCategories () {
      return this.categories.filter(category => {
        return category.level === 2 && category.include_in_menu === 1
      }).sort((a, b) => a.position - b.position)
    }
  },
  created () {
    EventBus.$on('megamenu-clicked', () => {
      this.clicked = true
      setTimeout(() => { this.clicked = false }, 2000)
    });
  },
  /* async mounted () {
    if (this.$refs.mainNav.scrollHeight === 0) {
      return;
    }
    this.$nextTick(async () => {
      loadMenuCategories()
    });
  }, */
  methods: {
    // Timeout value must be equal to transition-delay + transition-duration for the main nav dropdowns (see _header.scss)
    hoveredTrigger () {
      setTimeout(() => { this.hovered = true }, 1000)
    },
    hoveredRemove () {
      setTimeout(() => { this.hovered = false }, 1000)
    }
    /* addBodyClass: function() {
      myBody.classList.add('noscroll');
    },
    removeBodyClass: function() {
      myBody.classList.remove('noscroll');
    } */
  }
}
</script>
