<template>
  <footer :class="{ '': isCheckoutPage }">
    <!-- <newsletter class="footer--newsletter flex brdr-bottom-1 brdr-cl-secondary" v-if="!isCheckoutPage" /> -->

    <div class="footer--usp">
      <div class="footer--usp-wrap container">
        <a
          class="footer--usp-element hidden-xs"
          href="https://www.ekomi.de/bewertungen-zaun24.de.html"
          target="_blank"
        >
          <div class="footer--usp-element-icon">
            <img
              v-lazy="'/assets/icon/icon-rating.svg'"
              alt="eKomi Bewertungen für Zaun24"
            >
          </div>
          <div class="footer--usp-element-head">
            4.8 / 5 Sternen
          </div>
          <div class="footer--usp-element-content">
            eKomi Bewertungen<br>für zaun24.de
          </div>
        </a>

        <a
          class="footer--usp-element"
          :href="'tel:' + storeConfig('general/store_information/phone')"
        >
          <div class="footer--usp-element-icon">
            <img
              v-lazy="'/assets/icon/icon-usp-hotline.png'"
              alt="Zaun24 Hotline"
              width="30px"
              height="30px"
            >
          </div>
          <div class="footer--usp-element-head">
            <span class="hidden-xs">HOTLINE:</span> {{ storeConfig('general/store_information/phone') }}
          </div>
          <div class="footer--usp-element-content">
            Sprechen Sie mit unseren<br>Fachberatern
          </div>
        </a>

        <a
          class="footer--usp-element hidden-xs"
          href="https://www.youtube.com/user/zaun24decoga"
          target="_blank"
        >
          <div class="footer--usp-element-icon">
            <img
              v-lazy="'/assets/icon/icon-usp-montagevideos.png'"
              alt="Montagevideos"
              width="30px"
              height="30px"
            >
          </div>
          <div class="footer--usp-element-head">
            MONTAGEVIDEOS
          </div>
          <div class="footer--usp-element-content">
            Gartenzäune einfach montiert
          </div>
        </a>

        <router-link
          class="footer--usp-element"
          :to="localizedRoute('/lieferung')"
        >
          <div class="footer--usp-element-icon">
            <img
              v-lazy="'/assets/icon/icon-usp-versand.png'"
              alt="Versandkosten"
              width="30px"
              height="30px"
            >
          </div>
          <div class="footer--usp-element-head">
            VERSANDKOSTENFREI <span class="hidden-xs">PER<br>SPEDITION</span>
          </div>
          <div class="footer--usp-element-content">
            Innerhalb <span v-html="storeView.storeCode == 'zaun24_at' ? 'AT' : 'DE'" /> ab <span v-html="storeView.storeCode == 'zaun24_at' ? 500 : 350" /> &euro; Bestellwert
          </div>
        </router-link>

        <router-link
          class="footer--usp-element"
          :to="localizedRoute('/zahlungsarten')"
        >
          <div class="footer--usp-element-icon">
            <img
              v-lazy="'/assets/icon/icon-usp-finanzierung.png'"
              alt="Finanzierung"
              width="30px"
              height="30px"
            >
          </div>
          <div class="footer--usp-element-head" v-if="storeView.storeCode == 'zaun24_de'">
            FINANZIERUNG<span class="hidden-xs"> +<br>
              KAUF AUF RECHNUNG</span>
          </div>
          <div class="footer--usp-element-head" v-if="storeView.storeCode == 'zaun24_at'">
            KAUF AUF RECHNUNG
          </div>
          <div class="footer--usp-element-content">
            ... und weitere Zahlungsarten
          </div>
        </router-link>
      </div>
    </div>

    <div class="footer--main">
      <div class="footer--payment-trust">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-md-4">
              <div class="footer-payment">
                <div class="footer--header">
                  Zahlungsarten
                </div>

                <div class="footer--content">
                  <footer-trust-payment-methods />
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-md-6">
              <div class="footer-trust">
                <div class="footer--header">
                  Unsere Auszeichnungen
                </div>

                <div class="footer--content content-trust">
                  <footer-trust-seals-best />
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-md-2">
              <div class="footer-trust">
                <div class="footer--header">
                  Unsere Siegel
                </div>

                <div class="footer--content content-trust">
                  <footer-trust-seals />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="footer-main"
        v-if="!isCheckoutPage"
      >
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-lg-6 footer-row">
              <div class="row">
                <div class="col-xs-12 col-md-6">
                  <footer-accordion heading="Kontakt" :open="true">
                    <address>
                      decoga GmbH<br>
                      Otto-Hahn-Str. 23<br>
                      D-50997 Köln
                    </address>
                    <address>
                      <span>Telefon:</span> <a href="tel:00492236333666" target="_blank">{{ phoneNumber }}</a><br>
                      <span>WhatsApp:</span> <a href="https://wa.me/4922363336612?text=Hallo%20liebes%20ZAUN24-Team%2C%0D%0A" target="_blank">+49 2236 / 333 66-12</a><br>
                      <span>Email:</span> <a :href="mailTo(storeConfig('general/imprint/email'))">{{ storeConfig('general/imprint/email') }}</a><br>
                      <a href="/contacts">Zum Kontaktformular</a><br>
                    </address>
                  </footer-accordion>
                </div>

                <div class="col-xs-12 col-md-6">
                  <footer-accordion
                    heading="Warum bei Zaun24 kaufen"
                    class="footer--why-zaun24"
                  >
                    <ul ref="accordionBodyRef">
                      <li><img v-lazy="'/assets/icon/icon-check.svg'" alt="Top-Kundenservice" width="10" height="8"> Top-Kundenservice</li>
                      <li><img v-lazy="'/assets/icon/icon-check.svg'" alt="Sichere Zahlung" width="10" height="8"> Sichere Zahlung</li>
                      <li><img v-lazy="'/assets/icon/icon-check.svg'" alt="Versandkostenfrei" width="10" height="8"> Versandkostenfrei ab <span v-html="storeView.storeCode == 'zaun24_at' ? 500 : 350" />€</li>
                      <li><img v-lazy="'/assets/icon/icon-check.svg'" alt="Über 4.000 Kundenbwertungen" width="10" height="8"> Über 4.000 Kundenbewertungen</li>
                      <li><img v-lazy="'/assets/icon/icon-check.svg'" alt="eKomi Bewertungen für zaun24.de" width="10" height="8"> 4.8 / 5 Sternen eKomi Bewertungen für zaun24.de</li>
                    </ul>
                  </footer-accordion>
                </div>
              </div>
            </div>

            <div class="col-xs-12 col-lg-6 footer-row">
              <div class="row" id="accordion">
                <div class="col-xs-12 col-md-4">
                  <footer-accordion heading="Service">
                    <ul>
                      <li v-for="(title, url) in getFilteredAccordion(accordion.service)" :key="url">
                        <router-link :to="localizedRoute(url)">
                          {{ title }}
                        </router-link>
                      </li>
                    </ul>
                  </footer-accordion>
                </div>

                <div class="col-xs-12 col-md-4">
                  <footer-accordion heading="Rechtliches" open>
                    <ul>
                      <li><a href="https://usercentrics.com/privacy-policy" onclick="UC_UI.showSecondLayer(); return false;">Cookie-Einstellungen</a></li>
                      <li v-for="(title, url) in getFilteredAccordion(accordion.legal)" :key="url">
                        <router-link :to="localizedRoute(url)">
                          {{ title }}
                        </router-link>
                      </li>
                    </ul>
                  </footer-accordion>
                </div>

                <div class="col-xs-12 col-md-4">
                  <footer-accordion heading="Unternehmen">
                    <ul>
                      <li v-for="(title, url) in getFilteredAccordion(accordion.company)" :key="url">
                        <router-link :to="localizedRoute(url)">
                          {{ title }}
                        </router-link>
                      </li>
                    </ul>
                  </footer-accordion>
                </div>
              </div>
            </div>

            <div class="col-xs-12">
              <div class="footer--social">
                <a href="https://www.facebook.com/zaun24.de/" class="footer-social-link" target="_blank">
                  <img
                    v-lazy="'/assets/logo/icon-facebook.svg'"
                    alt="Facebook"
                    width="23"
                    height="23"
                  >
                </a>

                <a href="https://www.instagram.com/zaun24.decoga/" class="footer-social-link" target="_blank">
                  <img
                    v-lazy="'/assets/logo/icon-instagram.svg'"
                    alt="Instagram"
                    width="25px"
                    height="25px"
                  >
                </a>

                <a href="https://www.pinterest.de/decogazaun24/" class="footer-social-link" target="_blank">
                  <img
                    v-lazy="'/assets/logo/icon-pinterest.svg'"
                    alt="Pinterest"
                    width="25px"
                    height="25px"
                  >
                </a>

                <a href="https://www.youtube.com/user/zaun24decoga" class="footer-social-link" target="_blank">
                  <img
                    v-lazy="'/assets/logo/icon-youtube.svg'"
                    alt="Youtube"
                    width="27px"
                    height="20px"
                  >
                </a>

                <a href="https://wa.me/4922199899912?text=Hallo%20liebes%20ZAUN24-Team%2C%0D%0A" class="footer-social-link" target="_blank">
                  <img
                    v-lazy="'/assets/logo/icon-whatsapp.svg'"
                    alt="WhatsApp"
                    width="25px"
                    height="25px"
                  >
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bottom-links">
      <div class="copyright">
        © decoga GmbH – * der Streichpreis stellt den niedrigsten Artikelpreis innerhalb der letzten 30 Tage dar.
      </div>
    </div>

    <back-to-top bottom="15px" right="15px" visibleoffset="200">
      <button type="button" class="btn-top" aria-label="Back to top" />
    </back-to-top>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'
import { currentStoreView, localizedRoute } from '@vue-storefront/core/lib/multistore'
import CurrentPage from 'theme/mixins/currentPage'
// import LanguageSwitcher from '../../LanguageSwitcher.vue'
// import Newsletter from 'theme/components/core/blocks/Footer/Newsletter'
import BackToTop from 'theme/components/core/BackToTop'
import { getPathForStaticPage } from 'theme/helpers'
import config from 'config'

import FooterAccordion from 'theme/components/core/blocks/Footer/FooterAccordion.vue'
import FooterTrustPaymentMethods from 'theme/components/core/blocks/Footer/FooterTrustPaymentMethods.vue'
import FooterTrustSealsBest from 'theme/components/core/blocks/Footer/FooterTrustSealsBest.vue'
import FooterTrustSeals from 'theme/components/core/blocks/Footer/FooterTrustSeals.vue'

export default {
  mixins: [CurrentPage],
  name: 'MainFooter',
  methods: {
    goToAccount () {
      this.$bus.$emit('modal-toggle', 'modal-signup')
    },
    getLinkFor (path) {
      return localizedRoute(getPathForStaticPage(path))
    },
    mailTo (email) {
      return 'mailto:' + email;
    },
    getFilteredAccordion (accordion) {
      if (currentStoreView().storeCode === 'zaun24_at') {
        const forbidden = ['/batterieentsorgung', '/stellenangebote']
        accordion = Object.keys(accordion)
          .filter(key => !forbidden.includes(key))
          .reduce((obj, key) => {
            obj[key] = accordion[key]
            return obj
          }, {})
      }
      return accordion
    }
  },
  data () {
    return {
      accordion: {
        service: {
          '/zahlungsarten': 'Zahlungsarten',
          '/lieferung': 'Versandinformationen',
          '/lieferzeit': 'Informationen zur Lieferzeit',
          '/bestellvorgang': 'Informationen zum Bestellvorgang',
          '/faq': 'FAQ/Häufige Fragen'
        },
        legal: {
          '/agb': 'AGB',
          '/widerruf': 'Widerrufsbelehrung',
          '/datenschutzerklaerung': 'Datenschutzerklärung',
          '/impressum': 'Impressum',
          '/verpackungsgesetz': 'Verpackungsgesetz',
          '/batterieentsorgung': 'Batterieentsorgung'
        },
        company: {
          '/ueber-uns': 'Über ZAUN24',
          '/aktuelle-informationen': 'Aktuelle Informationen',
          '/gewerbliche-kunden': 'Gewerbliche Kunden',
          '/stellenangebote': 'Stellenangebote'
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      isLogged: 'user/isLoggedIn',
      storeConfig: 'storeConfig/getConfigValue'
    }),

    multistoreEnabled () {
      return config.storeViews.multistore
    },
    getVersionInfo () {
      return `v${process.env.__APPVERSION__} ${process.env.__BUILDTIME__}`
    },
    phoneNumber () {
      const phone = this.storeConfig('general/store_information/phone')
      return typeof phone === 'string' ? phone.replace(/^02/, '+49 (0) 2') : ''
    },
    storeView () {
      return currentStoreView()
    }
  },
  components: {
    // Newsletter,
    // LanguageSwitcher,
    BackToTop,
    FooterAccordion,
    FooterTrustPaymentMethods,
    FooterTrustSealsBest,
    FooterTrustSeals
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-secondary: color(secondary);

.icon {
  transition: 0.3s all;
}

.social-icon {
  width: 40px;
  height: 40px;
  &:hover,
  &:focus,
  &:active {
    .icon {
      fill: $color-secondary;
    }
  }
}
.links {
  list-style-type: none;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
}

.bottom-links {
  @media (max-width: 767px) {
    padding: 0;
  }
}

.footer__version-info {
  display: flex;
  color: $color-secondary;
  font-size: 0.7rem;
  @media (min-width: 768px) {
    margin-right: 1rem;
    font-size: 0.8rem;
  }
}

.underline {
  &:hover {
    color: $color-secondary;
    &:after {
      background-color: $color-secondary;
    }
  }
}
.legal-entity-link {
  text-align: center;
  @media (min-width: 768px) {
    display: none;
  }
}

.privacy-policy-link {
  text-align: right;
  @media (min-width: 768px) {
    display: none;
  }
}

@media (max-width: 595px) {
  .no-mobile {
    display: none;
  }

  .social {
    margin-top: 0;
  }

  .footer-links {
    padding-bottom: 30px;
  }
}
</style>
