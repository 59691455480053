<template>
  <router-link :to="localizedRoute('/')" :title="$t('Home Page')" class="no-underline inline-flex fix">
    <img
      :width="width"
      :height="height"
      :src="storeCode === 'zaun24_de' ? '/assets/logo.svg' : '/assets/logo-at.svg'"
      :alt="defaultTitle"
    >
  </router-link>
</template>

<script>
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  data () {
    const storeView = currentStoreView()
    return {
      defaultTitle: storeView.seo.defaultTitle ? storeView.seo.defaultTitle : config.seo.defaultTitle
    }
  },
  props: {
    width: {
      type: [String, Number],
      required: true
    },
    height: {
      type: [String, Number],
      required: true
    }
  },
  computed: {
    storeCode () {
      return currentStoreView().storeCode
    }
  }
}
</script>
