var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"modal-header"},[_c('img',{staticClass:"modal-close",attrs:{"slot":"close","src":"/assets/icon/icon-close.svg"},on:{"click":_vm.close},slot:"close"}),_vm._v("\n    "+_vm._s(_vm.$t('Register'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"modal-content pt30 pb60 px65 cl-secondary"},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('base-input',{attrs:{"type":"email","name":"email","autocomplete":"email","focus":"","placeholder":_vm.$t('E-mail address *'),"validations":[
          {
            condition: !_vm.$v.email.required && _vm.$v.email.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.email.email && _vm.$v.email.$error,
            text: _vm.$t('Please provide valid e-mail address.')
          }
        ]},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('div',{staticClass:"row"},[_c('base-input',{staticClass:"col-xs-6",attrs:{"type":"text","name":"first-name","autocomplete":"given-name","placeholder":_vm.$t('First name *'),"validations":[
            {
              condition: !_vm.$v.firstName.required && _vm.$v.firstName.$error,
              text: _vm.$t('Field is required.')
            },
            {
              condition: !_vm.$v.firstName.minLength,
              text: _vm.$t('Name must have at least 2 letters.')
            }
          ]},on:{"blur":function($event){return _vm.$v.firstName.$touch()}},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_vm._v(" "),_c('base-input',{staticClass:"col-xs-6",attrs:{"type":"text","name":"last-name","autocomplete":"last-name","placeholder":_vm.$t('Last name *'),"validations":[{
            condition: !_vm.$v.lastName.required && _vm.$v.lastName.$error,
            text: _vm.$t('Field is required.')
          }]},on:{"blur":function($event){return _vm.$v.lastName.$touch()}},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1),_vm._v(" "),_c('base-input',{ref:"password",attrs:{"type":"password","name":"password","autocomplete":"new-password","placeholder":_vm.$t('Password *'),"validations":[
          {
            condition: !_vm.$v.password.required && _vm.$v.password.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.password.minLength && _vm.$v.password.$error,
            text: _vm.$t('Password must have at least 8 letters.')
          }
        ]},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('base-input',{attrs:{"type":"password","name":"password-confirm","autocomplete":"new-password","placeholder":_vm.$t('Repeat password *'),"validations":[
          {
            condition: !_vm.$v.rPassword.required && _vm.$v.rPassword.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.rPassword.sameAsPassword && _vm.$v.rPassword.$error,
            text: _vm.$t('Passwords must be identical.')
          }
        ]},on:{"blur":function($event){return _vm.$v.rPassword.$touch()}},model:{value:(_vm.rPassword),callback:function ($$v) {_vm.rPassword=$$v},expression:"rPassword"}}),_vm._v(" "),_c('div',{staticClass:"options"},[_c('base-checkbox',{attrs:{"id":"terms","validations":[{
            condition: !_vm.$v.conditions.required && _vm.$v.conditions.$error,
            text: _vm.$t('You must accept the terms and conditions.')
          }]},on:{"blur":function($event){return _vm.$v.conditions.$reset()},"change":function($event){return _vm.$v.conditions.$touch()}},model:{value:(_vm.conditions),callback:function ($$v) {_vm.conditions=$$v},expression:"conditions"}},[_vm._v("\n          Ich akzeptiere die "),_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"/agb","title":"Zu den Allgemeinen Geschäftsbedingungen","target":"_blank"}},[_vm._v("Allgemeinen Geschäftsbedingungen")]),_vm._v(" *\n        ")])],1),_vm._v(" "),_c('button-full',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("\n        "+_vm._s(_vm.$t('Register an account'))+"\n      ")]),_vm._v(" "),_c('span',{staticClass:"link-alt"},[_vm._v("\n        "+_vm._s(_vm.$t('or'))+"\n        "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.switchElem.apply(null, arguments)}}},[_vm._v("\n          "+_vm._s(_vm.$t('login to your account'))+"\n        ")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }