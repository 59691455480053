var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"modal-header"},[_c('img',{staticClass:"modal-close",attrs:{"slot":"close","src":"/assets/icon/icon-close.svg"},on:{"click":_vm.close},slot:"close"}),_vm._v("\n    "+_vm._s(_vm.$t('Log in'))+"\n  ")]),_vm._v(" "),(_vm.hasRedirect)?_c('div',{staticClass:"pt10 pb10 px65 redirect-error"},[_c('p',{staticClass:"h5 mb0 mt0"},[_vm._v("\n      "+_vm._s(_vm.$t('You need to be logged in to see this page'))+"\n    ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"modal-content"},[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}}},[_c('base-input',{attrs:{"type":"email","name":"email","focus":"","placeholder":_vm.$t('E-mail address *'),"validations":[
          {
            condition: !_vm.$v.email.required && _vm.$v.email.$error,
            text: _vm.$t('Field is required.')
          },
          {
            condition: !_vm.$v.email.email && _vm.$v.email.$error,
            text: _vm.$t('Please provide valid e-mail address.')
          }
        ]},on:{"blur":function($event){return _vm.$v.email.$touch()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c('base-input',{attrs:{"type":"password","name":"password","placeholder":_vm.$t('Password *'),"validations":[{
          condition: !_vm.$v.password.required && _vm.$v.password.$error,
          text: _vm.$t('Field is required.')
        }]},on:{"blur":function($event){return _vm.$v.password.$touch()}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_vm._v(" "),_c('div',{staticClass:"options"},[_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.remindPassword.apply(null, arguments)}}},[_vm._v("\n            "+_vm._s(_vm.$t('Forgot the password?'))+"\n          ")])])]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","data-testid":"loginSubmit"}},[_vm._v("\n        "+_vm._s(_vm.$t('Log in to your account'))+"\n      ")]),_vm._v(" "),_c('a',{staticClass:"link-alt",attrs:{"href":"#","data-testid":"registerLink"},on:{"click":function($event){$event.preventDefault();return _vm.switchElem.apply(null, arguments)}}},[_vm._v("\n        "+_vm._s(_vm.$t('or'))+"\n        "+_vm._s(_vm.$t('register an account'))+"\n      ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }