import rootStore from '@vue-storefront/core/store'
import config from 'config'

function getCategoryFields () {
  const fieldBlacklist = ['description', 'short_description', 'meta_description', 'meta_title']
  return config.entities.category.includeFields.filter(v => !fieldBlacklist.includes(v));
}

export async function loadMenuChildCategoriesByLevel (level) {
  const levelCategories = rootStore.state['category-next'].menuCategories.filter(c => c.level === level);

  await rootStore.dispatch('category-next/fetchMenuCategories', {
    level: [level + 1],
    includeFields: getCategoryFields(),
    // skipCache: isServer,
    key: 'include_in_menu',
    value: 1,
    parent: Object.keys(levelCategories).map(key => levelCategories[key].id),
    onlyActive: true
  });
}

export async function loadMenuCategories () {
  await loadMenuChildCategoriesByLevel(2)
  await loadMenuChildCategoriesByLevel(3)
}
