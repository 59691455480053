<template>
  <div class="layer-container layer-2-container">
    <ul
      v-if="children"
      class="layer-2"
    >
      <li
        :key="subCategory.id"
        v-for="subCategory in children.slice(0, 7)"
      >
        <main-nav-level-two-inner
          :category="subCategory"
        />
      </li>
      <li
        v-if="children.length >= 7"
        class="link-all"
      >
        <div>
          <router-link
            @click.native="emitMegamenuClick"
            :to="categoryLink(category)"
          >
            Alle anzeigen
          </router-link>
        </div>
      </li>
    </ul>

    <!--<ul class="layer-2">
      <li
        v-for="(product, key) in products"
        :key="product.id"
      >
        <main-nav-level-three :product="product" />
      </li>
    </ul>-->
  </div>
</template>

<script>
import i18n from '@vue-storefront/i18n'
import config from 'config'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus/index'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import MainNavLevelTwoInner from 'theme/components/core/blocks/Custom/MainNavLevelTwoInner'

export default {
  name: 'MainNavLevelTwo',
  components: {
    MainNavLevelTwoInner
  },
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  computed: {
    children () {
      if (!config.entities.category.categoriesDynamicPrefetch && (this.category.children_data && this.category.children_data.length > 0 && this.category.children_data[0].name)) { // we're using dynamic prefetching and getting just category.children_data.id from 1.7
        return this.category.children_data
      } else {
        return this.$store.state['category-next'].menuCategories.filter(c => { return c.parent_id === this.category.id })
          .sort((a, b) => a.position - b.position)
      }
    }
  },
  methods: {
    categoryLink (category) {
      return formatCategoryLink(category)
    },
    emitMegamenuClick () {
      EventBus.$emit('megamenu-clicked');
      this.clicked = true;
    }
  }
}
</script>
