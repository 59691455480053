<template>
  <div class="layer-container layer-3-container">
    <ul
      v-if="categories"
      class="layer-3"
    >
      <li
        :key="category.slug"
        v-for="category in categories"
      >
        <div class="megamenu-title">
          {{ category.name }}
        </div>
        <!-- div class="megamenu-subtitle">
          [Unsere Bestseller]
        </div -->
        <div class="image">
          <img
            :src="getThumbnail(category.image,500,300,'category')"
            :alt="category.name"
          >
        </div>
        <div
          class="badge badge-sm badge-secondary"
          v-if="category.pulldowntitle > 0"
        >
          {{ category.pulldowntitle }}
        </div>
        <div class="megamenu-properties">
          <span v-html="category.pulldowntext" />
        </div>
        <router-link
          class="btn btn-primary"
          @click.native="emitMegamenuClick"
          :to="categoryLink(category)"
        >
          Zum Produkt
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import i18n from '@vue-storefront/i18n'
import config from 'config'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus/index'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'

export default {
  name: 'MainNavLevelThree',
  props: {
    id: {
      type: [String, Number],
      required: true
    },
    categoryLinks: {
      type: null,
      required: false,
      default: false
    },
    parentSlug: {
      type: String,
      required: false,
      default: ''
    },
    parentPath: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    categories () {
      if (!config.entities.category.categoriesDynamicPrefetch && (this.categoryLinks && this.categoryLinks.length > 0 && this.categoryLinks[0].name)) { // we're using dynamic prefetching and getting just category.children_data.id from 1.7
        return this.categoryLinks
      } else {
        return this.$store.state['category-next'].menuCategories.filter(c => { return c.id === this.id })
          .sort((a, b) => a.position - b.position)
      }
    }
  },
  methods: {
    emitMegamenuClick () {
      EventBus.$emit('megamenu-clicked');
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    }
  }
}
</script>
