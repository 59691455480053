<template>
  <div
    @mouseenter="hoverstart"
    @mouseleave="hoverend"
    :class="{ 'hovered' : hovered, 'clicked' : clicked }"
  >
    <router-link
      @click.native="emitMegamenuClick"
      @mouseleave.native="clicked = false"
      :to="categoryLink(category)"
    >
      <span>{{ category.name }}</span>
      <sub-btn
        v-if="category.children_count > 0"
        :id="category.id"
        :name="category.name"
      />
    </router-link>
    <div
      v-if="category.children_count > 0"
      class="megamenu-container"
    >
      <div class="container">
        <main-nav-level-one
          v-if="initialized" v-show="hovered"
          :category="category"
        />
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'

import SubBtn from 'theme/components/core/blocks/Custom/SubBtn'
import MainNavLevelOne from 'theme/components/core/blocks/Custom/MainNavLevelOne'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import { loadMenuCategories } from 'theme/helpers/menu'

export default {
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      initialized: false,
      hovered: false,
      clicked: false
    }
  },
  components: {
    MainNavLevelOne,
    SubBtn
  },
  methods: {
    emitMegamenuClick () {
      EventBus.$emit('megamenu-clicked');
      this.clicked = true;
    },
    hoverstart () {
      if (!this.initialized) {
        loadMenuCategories();
      }
      this.initialized = true
      if (this.timerout) {
        clearTimeout(this.timerout);
      }
      this.timerin = setTimeout(() => { this.hovered = true }, 250);
    },
    hoverend () {
      if (this.timerin) {
        clearTimeout(this.timerin);
      }
      this.timerout = setTimeout(() => { this.hovered = false }, 250);
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    }
  }
}
</script>
