<template>
  <div class="layer-container layer-1-container">
    <ul
      v-if="children"
      class="layer-1"
    >
      <li
        :key="childCategory.slug"
        v-for="(childCategory, index) in children.slice(0, 15)"
      >
        <main-nav-level-one-inner
          :category="childCategory"
          :index="index"
        />
      </li>
      <li
        v-if="children.length >= 14"
        class="link-all"
      >
        <div>
          <router-link
            @click.native="emitMegamenuClick"
            :to="categoryLink(category)"
          >
            Alle anzeigen
          </router-link>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import i18n from '@vue-storefront/i18n'
import config from 'config'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus/index'

import MainNavLevelOneInner from 'theme/components/core/blocks/Custom/MainNavLevelOneInner'
// import { prepareQuery } from '@vue-storefront/core/modules/catalog/queries/common'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'

export default {
  name: 'MainNavLevelOne',
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  components: {
    MainNavLevelOneInner
  },
  computed: {
    children () {
      if (!config.entities.category.categoriesDynamicPrefetch && (this.category.children_data && this.category.children_data.length > 0 && this.category.children_data[0].name)) { // we're using dynamic prefetching and getting just category.children_data.id from 1.7
        return this.category.children_data
      } else {
        return this.$store.state['category-next'].menuCategories.filter(c => { return c.parent_id === this.category.id })
          .sort((a, b) => a.position - b.position)
      }
    }
  },
  methods: {
    categoryLink (category) {
      return formatCategoryLink(category)
    },
    emitMegamenuClick () {
      EventBus.$emit('megamenu-clicked');
      this.clicked = true;
    }
  }
}
</script>
