var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('header',{staticClass:"modal-header"},[_c('img',{staticClass:"modal-close",attrs:{"slot":"close","src":"/assets/icon/icon-close.svg"},on:{"click":_vm.close},slot:"close"}),_vm._v("\n    "+_vm._s(_vm.$t('Reset password'))+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"modal-content pt30 pb60 px65 cl-secondary"},[(!_vm.passwordSent)?[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail.apply(null, arguments)}}},[_c('div',{staticClass:"mb20"},[_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('Enter your email to receive instructions on how to reset your password.'))+"\n          ")]),_vm._v(" "),_c('base-input',{attrs:{"type":"email","name":"email","focus":"","placeholder":_vm.$t('E-mail address *'),"validations":[
              {
                condition: !_vm.$v.email.required && _vm.$v.email.$error,
                text: _vm.$t('Field is required.')
              },
              {
                condition: !_vm.$v.email.email && _vm.$v.email.$error,
                text: _vm.$t('Please provide valid e-mail address.')
              }
            ]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_vm._v(" "),_c('button-full',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v("\n          "+_vm._s(_vm.$t('Reset password'))+"\n        ")]),_vm._v(" "),_c('span',{staticClass:"link-alt"},[_vm._v("\n          "+_vm._s(_vm.$t('or'))+"\n          "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.switchElem.apply(null, arguments)}}},[_vm._v("\n            "+_vm._s(_vm.$t('return to log in'))+"\n          ")])])],1)]:_vm._e(),_vm._v(" "),(_vm.passwordSent)?[_c('form',{staticClass:"py20"},[_c('p',{},[_vm._v("\n          "+_vm._s(_vm.$t("We've sent password reset instructions to your email. Check your inbox and follow the link."))+"\n        ")]),_vm._v(" "),_c('button-full',{staticClass:"btn btn-primary",attrs:{"type":"link"},nativeOn:{"click":function($event){return _vm.switchElem.apply(null, arguments)}}},[_vm._v("\n          "+_vm._s(_vm.$t('Back to login'))+"\n        ")])],1)]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }