<template>
  <div
    @mouseenter="hoverstart"
    @mouseleave="hoverend"
    :class="{ 'hovered': hovered }"
  >
    <router-link
      @click.native="emitMegamenuClick"
      :to="categoryLink(category)"
    >
      {{ category.name }}
      <sub-btn
        v-if="category.children_count > 0"
        :id="category.id"
        :name="category.name"
      />
    </router-link>

    <main-nav-level-two
      v-show="hovered || index == 0"
      :category="category"
    />
  </div>
</template>

<script>
import i18n from '@vue-storefront/i18n'
import config from 'config'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus/index'

import SubBtn from 'theme/components/core/blocks/Custom/SubBtn'
import MainNavLevelTwo from 'theme/components/core/blocks/Custom/MainNavLevelTwo'
// import { prepareQuery } from '@vue-storefront/core/modules/catalog/queries/common'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'

export default {
  name: 'MainNavLevelOneInner',
  props: {
    category: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      hovered: false
    };
  },
  components: {
    MainNavLevelTwo,
    SubBtn
  },
  methods: {
    emitMegamenuClick () {
      EventBus.$emit('megamenu-clicked');
    },
    hoverstart () {
      if (this.timerout) {
        clearTimeout(this.timerout);
      }
      this.timerin = setTimeout(() => { this.hovered = true }, 250);
    },
    hoverend () {
      if (this.timerin) {
        clearTimeout(this.timerin);
      }
      this.timerout = setTimeout(() => { this.hovered = false }, 250);
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    }
  }
}
</script>
