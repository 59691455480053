<template>
  <button
    type="button"
    :aria-label="$t('Show subcategories')"
    data-testid="categoryButton"
  />
</template>

<script>
import config from 'config'

export default {
  name: 'SubBtn',
  props: {
    id: {
      type: null,
      default: ''
    },
    type: {
      type: String,
      default: 'next'
    },
    name: {
      type: String,
      default: ''
    }
  }
}
</script>
