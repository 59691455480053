<template>
  <div
    @mouseenter="hoverstart"
    @mouseleave="hoverend"
    :class="{ 'hovered': hovered }"
  >
    <router-link
      @click.native="emitMegamenuClick"
      :to="categoryLink(category)"
    >
      <div class="image">
        <img
          :src="getThumbnail(category.image,200,200,'category')"
          :alt="category.name"
        >
      </div>
      {{ category.name }}
    </router-link>

    <main-nav-level-three
      :category-links="category.children_data"
      :id="category.id"
      :parent-slug="category.slug"
      :parent-path="category.url_path"
    />
  </div>
</template>

<script>
import i18n from '@vue-storefront/i18n'
import config from 'config'
import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus/index'
import MainNavLevelThree from 'theme/components/core/blocks/Custom/MainNavLevelThree'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'

export default {
  name: 'MainNavLevelTwoInner',
  components: {
    MainNavLevelThree
  },
  props: {
    category: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      hovered: false
    };
  },
  methods: {
    emitMegamenuClick () {
      EventBus.$emit('megamenu-clicked');
    },
    hoverstart () {
      if (this.timerout) {
        clearTimeout(this.timerout);
      }
      this.timerin = setTimeout(() => { this.hovered = true }, 250);
    },
    hoverend () {
      if (this.timerin) {
        clearTimeout(this.timerin);
      }
      this.timerout = setTimeout(() => { this.hovered = false }, 250);
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    }
  }
}
</script>
