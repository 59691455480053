<template>
  <button
    type="button"
    class="btn-cart"
    :class="{ 'is-adding': isAddingToCart }"
    @click="openMicrocart"
    data-testid="openMicrocart"
    :aria-label="$t('Open microcart')"
  >
    <i />
    <!-- span
      v-if="cartTotals.value"
      class="btn-cart-amount"
    >
      {{ cartTotals.value | price() }}
    </span -->
    <span
      class="btn-cart-count"
      v-cloak
      v-show="totalQuantity"
      data-testid="minicartCount"
    >
      <span v-if="isAddingToCart" class="loading-mask"><span class="loader" /></span>
      {{ totalQuantity }}
    </span>
  </button>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import MicrocartIcon from '@vue-storefront/core/compatibility/components/blocks/Header/MicrocartIcon'

export default {
  computed: {
    ...mapGetters({
      totalQuantity: 'cart/getItemsTotalQuantity',
      cartTotalsGetter: 'cart/getTotals',
      isAddingToCart: 'cart/getIsAdding'
    }),
    cartTotals: function () {
      return this.cartTotalsGetter.find((item) => item.code === 'grand_total')
    }
  },
  methods: {
    ...mapActions({
      openMicrocart: 'ui/toggleMicrocart'
    })
  }
}
</script>

<style scoped>
  .minicart-count {
    top: 7px;
    left: 50%;
    min-width: 16px;
    min-height: 16px;
    border-radius: 10px;
  }
</style>
