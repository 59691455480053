<template>
  <div class="footer-column accordion" :class="accordionClasses">
    <div class="footer--header accordion-header" @click="toggleAccordion">
      {{ heading }}
    </div>

    <div class="footer--content accordion-body" :style="accordionBody">
      <div ref="accordionBodyRef">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterAccordion',
  props: {
    heading: {
      type: String,
      required: false,
      default: ''
    },
    content: {
      type: String,
      required: false,
      default: ''
    },
    open: {
      type: Boolean,
      required: false,
      default: null
    }
  },
  methods: {
    toggleAccordion: function () {
      this.isOpen = !this.isOpen;
    },
    matchHeight: function () {
      const heightString = this.$refs.accordionBodyRef.clientHeight + 'px';
      this.$set(this.accordionBody, 'max-height', heightString);
    }
  },
  data () {
    return {
      isOpen: false,
      accordionBody: { }
    }
  },
  created () {
    if (this.open) {
      this.isOpen = true
    }
  },
  mounted () {
    this.matchHeight();
  },
  computed: {
    accordionClasses: function () {
      return {
        'is-closed': !this.isOpen
      };
    }
  }
}
</script>
