<template>
  <div
    class="header-top-login dropdown"
    data-testid="accountButton"
    @click.self="goToAccount();showMenu = true;"
    @keyup.enter="goToAccount"
    tabindex="0"
    role="button"
    @mouseover="showMenu = true"
    @mouseout="showMenu = false"
  >
    <button
      type="button"
      :aria-label="$t('Open my account')"
    />

    <no-ssr>
      <div v-show="currentUser" :class="['dropdown-content', !showMenu ? 'dropdown-content__hidden' : '']">
        <div>
          <div v-for="(page, index) in navigation" :key="index" @click="notify(page.title)">
            <router-link @click.native="showMenu = false" :to="localizedRoute(page.link)">
              {{ page.title }}
            </router-link>
          </div>
        </div>
        <div>
          <div>
            <a href="#" @click.prevent="logout">
              {{ $t('Logout') }}
            </a>
          </div>
        </div>
      </div>
    </no-ssr>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import AccountIcon from '@vue-storefront/core/compatibility/components/blocks/Header/AccountIcon'

export default {
  mixins: [AccountIcon],
  components: {
    'no-ssr': NoSSR
  },
  data () {
    return {
      showMenu: false,
      navigation: [
        { title: this.$t('My profile'), link: '/my-account' },
        { title: this.$t('My shipping details'), link: '/my-account/shipping-details' },
        { title: this.$t('My newsletter'), link: '/my-account/newsletter' },
        { title: this.$t('My orders'), link: '/my-account/orders' },
        // { title: this.$t('My loyalty card'), link: '#' },
        // { title: this.$t('My product reviews'), link: '#' },
        { title: this.$t('My Recently viewed products'), link: '/my-account/recently-viewed' }
      ]
    }
  },
  methods: {
    notify (title) {
      if (title === 'My loyalty card' || title === 'My product reviews') {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: this.$t('This feature is not implemented yet! Please take a look at https://github.com/DivanteLtd/vue-storefront/issues for our Roadmap!'),
          action1: { label: this.$t('OK') }
        })
      }
    }
  }
}
</script>
