<template>
  <div
    class="header"
    :class="{ 'has-offer': storeConfig('design/discount/discount_title') }"
  >
    <header
      class="fixed w-100"
      :class="{ 'is-visible': navVisible, 'is-scrolled': navScrolled }"
    >
      <div class="header-top">
        <div class="container">
          <ul class="header-top-info">
            <li>
              <router-link :to="localizedRoute('/contacts')">
                <img src="/assets/icon/icon-contact.svg" alt="Schreiben Sie uns">
                <span>Schreiben Sie uns</span>
              </router-link>
            </li>
          </ul>

          <div class="header-top-search">
            <search-icon />
            <input type="search" placeholder="Suchen …">
          </div>

          <div class="header-links">
            <router-link :to="localizedRoute('/i/faq')" class="header-top-faq" title="Faq" />
            <account-icon />
          </div>
        </div>
      </div>

      <div class="header-main">
        <div class="container">
          <div class="row header-main-top" v-if="!isCheckoutPage || isThankYouPage">
            <div class="col-xs-4 col-sm-3 col-md-3 header-logo">
              <logo width="auto" height="41px" />
            </div>

            <div class="col-xs-4 col-sm-6 hidden-md hidden-lg hidden-xl header-service-mobile">
              <div class="header-service">
                <img v-lazy="'/assets/icon/icon-support.png'" alt="Zaunberatung">
                <a :href="'tel:' + storeConfig('general/store_information/phone')" class="header-service-inner">
                  <div>Zaunberatung:</div>
                  <div>{{ storeConfig('general/store_information/phone') }}</div>
                  <div><span>{{ storeConfig('general/store_information/hours') }}</span></div>
                </a>
              </div>
            </div>

            <div class="col-sm-12 col-md-6 header-info">
              <div class="header-offer" v-if="storeConfig('design/discount/discount_title')">
                <img src="/assets/icon/icon-discount.svg" alt="Rabatt-Aktion">
                <div class="header-offer-inner">
                  <router-link :to="localizedRoute(storeConfig('design/discount/discount_link'))" class="cl-tertiary links">
                    <div>{{ storeConfig('design/discount/discount_title') }}</div>
                    <div><span>{{ storeConfig('design/discount/discount_slogan') }}</span></div>
                  </router-link>
                </div>
              </div>

              <div class="header-service">
                <img v-lazy="'/assets/icon/icon-support.png'" alt="Zaunberatung">
                <a :href="'tel:' + storeConfig('general/store_information/phone')" class="header-service-inner">
                  <div>Zaunberatung: {{ storeConfig('general/store_information/phone') }}</div>
                  <div><span>{{ storeConfig('general/store_information/hours') }}</span></div>
                </a>
              </div>
            </div>

            <div class="col-xs-4 col-sm-3 col-md-3 header-action">
              <microcart-icon />
              <hamburger-icon class="visible-xs" />
            </div>
          </div>

          <main-nav
            class="hidden-xs"
          />

          <div class="row between-xs middle-xs px15 py5" v-if="isCheckoutPage && !isThankYouPage">
            <div class="col-xs-5 col-md-3 middle-xs">
              <div>
                <router-link :to="localizedRoute('/')" class="cl-tertiary links">
                  {{ $t('Return to shopping') }}
                </router-link>
              </div>
            </div>
            <div class="col-xs-2 col-md-6 center-xs">
              <logo width="auto" height="41px" />
            </div>
            <div class="col-xs-5 col-md-3 end-xs">
              <div>
                <a v-if="!currentUser" href="#" @click.prevent="gotoAccount" class="cl-tertiary links">
                  {{ $t('Login to your account') }}
                </a>
                <span v-else>
                  {{ $t('You are logged in as') }} {{ currentUser.firstname }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>

    <div class="header-placeholder" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CurrentPage from 'theme/mixins/currentPage'
import AccountIcon from 'theme/components/core/blocks/Header/AccountIcon'
import HamburgerIcon from 'theme/components/core/blocks/Header/HamburgerIcon'
import Logo from 'theme/components/core/Logo'
import MicrocartIcon from 'theme/components/core/blocks/Header/MicrocartIcon'
import SearchIcon from 'theme/components/core/blocks/Header/SearchIcon'
import MainNav from 'theme/components/core/blocks/Custom/MainNav'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  components: {
    AccountIcon,
    HamburgerIcon,
    Logo,
    MicrocartIcon,
    SearchIcon,
    MainNav
  },
  mixins: [CurrentPage],
  data () {
    return {
      navVisible: true,
      navScrolled: false,
      isScrolling: false,
      scrollTop: 0,
      lastScrollTop: 0,
      navbarHeight: 63
    }
  },
  computed: {
    ...mapState({
      isOpenLogin: state => state.ui.signUp,
      currentUser: state => state.user.current
    }),
    ...mapGetters({
      storeConfig: 'storeConfig/getConfigValue'
    }),
    isThankYouPage () {
      return this.$store.state.checkout.isThankYouPage
        ? this.$store.state.checkout.isThankYouPage
        : false
    },
    storeView () {
      return currentStoreView()
    }
  },
  beforeMount () {
    window.addEventListener(
      'scroll',
      () => {
        this.isScrolling = true
      },
      { passive: true }
    )

    setInterval(() => {
      if (this.isScrolling) {
        this.hasScrolled()
        this.isScrolling = false
      }
    }, 250)
  },
  methods: {
    gotoAccount () {
      this.$bus.$emit('modal-toggle', 'modal-signup')
    },
    hasScrolled () {
      this.scrollTop = window.scrollY
      if (
        this.scrollTop > this.lastScrollTop &&
        this.scrollTop > this.navbarHeight
      ) {
        this.navVisible = false
        document.body.classList.remove('is-visible')
      } else {
        this.navVisible = true
        document.body.classList.add('is-visible')
      }
      if (this.scrollTop > 5) {
        this.navScrolled = true
        document.body.classList.add('is-scrolled')
      } else {
        this.navScrolled = false
        document.body.classList.remove('is-scrolled')
      }
      this.lastScrollTop = this.scrollTop
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-icon-hover: color(secondary, $colors-background);

.icon {
  opacity: 0.6;
  &:hover,
  &:focus {
    background-color: $color-icon-hover;
    opacity: 1;
  }
}
.right-icons {
  //for edge
  float: right;
}
.header-placeholder {
  height: 54px;
}
.links {
  text-decoration: underline;
}
@media (max-width: 767px) {
  .row.middle-xs {
    margin: 0 -15px;

    &.py5 {
      margin: 0;
    }
  }
  .col-xs-2:first-of-type {
      padding-left: 0;
  }
  .col-xs-2:last-of-type {
      padding-right: 0;
  }
  a, span {
    font-size: 12px;
  }
}
</style>
