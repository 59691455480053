<template>
  <div class="custom-notification" v-if="storeConfig('design/custom_notification/custom_notification_title') && isOpen">
    <div class="custom-notification__content">
      <h3 class="custom-notification__title">
        {{ storeConfig('design/custom_notification/custom_notification_title') }}
      </h3>
      <div class="custom-notification__description" v-html="storeConfig('design/custom_notification/custom_notification_description')" />
    </div>
    <div class="custom-notification__actions">
      <span
        class="btn btn-white btn-check"
        @click="accept"
        @keyup.enter="accept"
      >{{ $t('Close') }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'CustomNotification',
  computed: {
    ...mapGetters({
      storeConfig: 'storeConfig/getConfigValue'
    })
  },
  data () {
    return {
      isOpen: false
    }
  },
  mounted () {
    this.$store.dispatch('claims/check', { claimCode: 'customNotificationAccepted' }).then((cookieClaim) => {
      const TWO_WEEKS_IN_MS = 1000 * 60 * 60 * 24 * 14
      if (!cookieClaim || ((new Date().getTime() - TWO_WEEKS_IN_MS) - new Date(cookieClaim.created_at).getTime()) > 0) {
        this.isOpen = true
        this.$store.dispatch('claims/set', { claimCode: 'customNotificationAccepted', value: false })
      } else {
        this.isOpen = !cookieClaim.value
      }
    })
  },
  methods: {
    accept () {
      this.$store.dispatch('claims/set', { claimCode: 'customNotificationAccepted', value: true })
      this.isOpen = false
    }
  }
}
</script>
